<template>
  <PromotionTemplate
    :bannerStyle="{ backgroundImage: `url(${rwdBannerBg})`, backgroundPosition: 'right' }"
    :hasBanner="banner"
    :class="bannerRwdClass"
    ref="bannerItem"
  >
    <template #bannerItem>
      <BannerItemV2 v-bind="banner" :lang="imgLang" @getCurrentBannerRwdClass="getCurrentBannerRwdClass"></BannerItemV2>
    </template>

    <template #content>
      <div :style="{ 'margin-top': `${usedHeight}px !important` }">
        <AppPromotionInfo
          title="promotion.nfp.title"
          desc="promotion.nfp.desc"
          joinButtonDesc="promotion.nfp.buttonDesc"
          joinButtonTitle="promotion.nfp.buttonTitle"
          claimTitle="promotion.nfp.claimTitle"
          :claimDescriptions="$t('promotion.nfp.claimDescriptions')"
          eligibilityTitle="promotion.nfp.eligibilityTitle"
          :eligibilityDescriptions="$t('promotion.nfp.eligibleDescriptions')"
          :appImage="appImage"
          appLink="https://puprime.onelink.me/O5Jx/NFPPROMOCLIENTPORTAL"
          tnc="promotion.depositBonus.tnc"
          :tncLink="tncLink"
          tncLinkLabel="promotion.depositBonus.link"
        />
      </div>
    </template>
  </PromotionTemplate>
</template>

<script>
import { PROMOTION_TYPE } from '@/constants/promotion';
import PromotionTemplate from '@/components/promotion/common/PromotionTemplate.vue';
import BannerItemV2 from '@/components/banner/item/v2/BannerItem.vue';
import AppPromotionInfo from '@/components/promotion/common/AppPromotionInfo.vue';

export default {
  name: 'NfpPromotion',
  components: {
    PromotionTemplate,
    BannerItemV2,
    AppPromotionInfo
  },
  data() {
    return {
      banner: null,
      bannerRwdClass: null,
      usedHeight: 0,
      appImage: ''
    };
  },
  computed: {
    campaignID() {
      let campaign =
        this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === PROMOTION_TYPE.NFP) || {};
      if (!campaign.id) {
        this.$router.push('/404');
        return null;
      }
      return campaign.id;
    },
    imgLang() {
      return this.$store.state.common.lang;
    },
    rwdBannerBg() {
      if (this.banner?.img) {
        switch (this.bannerRwdClass) {
          case 'long':
          case 'mid-long':
            return this.banner?.img;
          case 'mid':
          case 'short':
          case 'xx-short':
          case 'xxx-short':
          case 'mid-short':
            return this.banner?.imgMedium;
          case 'x-short':
          default:
            return this.banner?.imgSmall;
        }
      }

      return null;
    },
    tncLink() {
      return this?.$store?.state?.common?.regulator === 'fsa'
        ? 'https://www.puprime.com/pdf/0528/NFP_Term_and_Condition.pdf'
        : 'https://www.puprime.net/pdf/0528/NFP_Term_and_Condition_Net.pdf';
    }
  },
  async mounted() {
    this.getBannerSetting();
    window.addEventListener('resize', this.handleResize);
    this.$nextTick(this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getBannerSetting() {
      const regulatorConfig = this.$config.banner(this.regulator).filter(el => el.promotionId === this.campaignID)[0];

      this.banner = { ...regulatorConfig };
    },
    getCurrentBannerRwdClass(bannerRwdClass) {
      this.bannerRwdClass = bannerRwdClass;
    },
    setHeight() {
      let tempHeight = this.$refs.bannerItem?.getHeight?.() ?? 0;
      this.usedHeight = tempHeight > 255 ? tempHeight - 255 : 0;
    },
    handleResize() {
      this.setHeight();
      this.setAppImageResize();
    },
    setAppImageResize() {
      if (window.innerWidth < 500) {
        this.appImage = require('@/assets/images/promotion/nfpPromotion/nfp_desc-small.png');
      } else {
        this.appImage = require('@/assets/images/promotion/nfpPromotion/nfp_desc.png');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/nfp.scss';
</style>
